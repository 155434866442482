table tbody tr:hover {
    background-color: #e5f2ff;
}

.btn-link, a {
    text-decoration: none;
}

.btn-link:hover, a:hover {
    text-decoration: underline;
}

.wide-column {
    width: 300px;
}
